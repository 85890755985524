<mat-toolbar color="primary">
    <span>Carte de Manjocarn</span>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="menu-container">
    <ng-container *ngFor="let type of menuTypes">
        <mat-card class="menu-card">
            <mat-card-header>
                <mat-card-title>{{ type }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="menu-item" *ngFor="let item of filteredMenu(type)">
                    <div class="item-header">
                        <h3>{{ item.name }}</h3>
                        <span class="price">{{ item.price | currency:'EUR' }}</span>
                    </div>
                    <p>{{ item.description }}</p>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
</div>