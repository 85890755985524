import { Component } from '@angular/core';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrl: './payer.component.scss'
})
export class PayerComponent {

}
