import { Component } from '@angular/core';

@Component({
  selector: 'app-manjocarn',
  templateUrl: './manjocarn.component.html',
  styleUrl: './manjocarn.component.scss'
})
export class ManjocarnComponent {

}
