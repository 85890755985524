<div class="fullscreen-wrapper">
    <div class="content-overlay">
        <h1 class="rustic-title">
            <span class="letter">M</span>
            <span class="letter">a</span>
            <span class="letter">n</span>
            <span class="letter">j</span>
            <span class="letter">o</span>
            <span class="letter">c</span>
            <span class="letter">a</span>
            <span class="letter">r</span>
            <span class="letter">n</span>
        </h1>
    </div>

    <nav class="flex-nav">
        <button *ngFor="let item of navItems; let i = index" class="nav-item" [attr.data-info]="item.title"
            (click)="openDialog(item)" [style.--item-index]="i">
            <div class="nav-content">
                <img [src]="item.icon" [alt]="item.title" class="nav-icon">
                <span class="nav-title">{{ item.title }}</span>
            </div>
        </button>
    </nav>
</div>