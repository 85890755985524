<mat-toolbar color="primary">
    <span>Événements</span>
    <button mat-icon-button mat-dialog-close aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div class="content-container">
    <div class="event-list">
        <h2>Prochains Événements</h2>
        <ul>
            <li *ngFor="let event of events" class="event-item">
                <div class="event-date">{{ event.date }}</div>
                <div class="event-description">{{ event.description }}</div>
            </li>
        </ul>
    </div>
</div>